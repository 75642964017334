import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button } from '../app/components/Styles';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player'
import Logo from "../app/components/Henkel_Logo.png"
import { Image, QRCode } from 'antd';
import { People, QrCode, QuestionMark } from '@mui/icons-material';
import HenkelWhite from "../app/components/Henkel_Logo_RGB_White.png"

export const Home = ({ }) => {

    const navigate = useNavigate()

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2 }}>
                <Image height={100} preview={false} src={"https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/Henkel_Logo.png"} />
                <Typography variant="h3" sx={{ color: "var(--secColor)" }}>GUC Career Fair 2024</Typography>
            </Box>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", marginBottom: 10 }}>
                <Box className="position-relative" width={"70vw"} height={"60vh"} sx={{ ...centered_flex_box, flexDirection: "column", padding: 2, marginBottom: 5 }}>
                    {/* <iframe src="https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/main-header.mp4" width={860} height={440}/> */}
                    {/* <ReactPlayer url={"https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/main-header.mp4"} loop playing muted width={"100%"} height={"100%"} /> */}
                    <video width={820} height={400} autoPlay muted loop>
                        <source src="https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/main-header.mp4" />
                    </video>
                    <Grid container spacing={2} sx={{ padding: 5 }}>
                        <Grid item sx={{ ...centered_flex_box }} xs={4}>
                            <QRCode
                                value='https://www.linkedin.com/company/henkel/'
                                icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png"
                            />
                        </Grid>
                        <Grid item sx={{ ...centered_flex_box }} xs={4}>
                            <QRCode
                                value='https://www.instagram.com/henkel/'
                                icon="https://cdn.pixabay.com/photo/2020/11/15/06/18/instagram-logo-5744708_640.png"
                            />
                        </Grid>
                        <Grid item sx={{ ...centered_flex_box }} xs={4}>
                            <QRCode
                                value='https://www.henkel.com/'
                                icon="https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/logo192.png"
                            />
                        </Grid>
                    </Grid>
                    {/* <Button className="position-absolute top-100 start-0 translate-middle" sx={{ background: "var(--secColor)", borderRadius: 100, width: 100, height: 100 }}><QrCode sx={{ width: 80, height: 80 }} /></Button> */}
                    <Box sx={{ ...centered_flex_box, flexDirection: "column", marginLeft: 5 }} className="position-absolute top-50 start-100 translate-middle">
                        <Box sx={{ ...centered_flex_box, flexDirection: "column", marginBottom: 3 }}>
                            <Button onClick={() => navigate("/Team")} sx={{ ...main_button, borderRadius: "10vw", width: "8vw", height: "8vw" }}>
                                <People
                                    sx={{
                                        width: "5vw",
                                        height: "5vw",
                                        //animation: 'growShrink 2s infinite alternate', // growShrink is the name of the keyframes animation
                                    }}
                                />
                            </Button>
                            <Typography variant="p" fontWeight="bold" fontSize={25} textAlign="center" sx={{ color: "var(--secColor)" }}>Meet the Team</Typography>
                        </Box>
                        {/* <Box sx={{ ...centered_flex_box, flexDirection: "column", marginBottom: 3 }}>
                            <Button onClick={() => navigate("/AllMajors")} sx={{ ...main_button, borderRadius: "10vw", width: "8vw", height: "8vw" }}>
                                <Avatar
                                    src={HenkelWhite}
                                    sx={{
                                        width: "5vw",
                                        height: "5vw",
                                        //animation: 'growShrink 2s infinite alternate', // growShrink is the name of the keyframes animation
                                    }}
                                />
                            </Button>
                            <Typography variant="p" fontWeight="bold" fontSize={25} textAlign="center" sx={{ color: "var(--secColor)" }}>About Henkel</Typography>
                        </Box> */}
                        <Box sx={{ ...centered_flex_box, flexDirection: "column", marginBottom: 3 }}>
                            <Button onClick={() => navigate("/AllMajors")} sx={{ ...main_button, borderRadius: "10vw", width: "8vw", height: "8vw" }}>
                                <QuestionMark
                                    sx={{
                                        width: "5vw",
                                        height: "5vw",
                                        //animation: 'growShrink 2s infinite alternate', // growShrink is the name of the keyframes animation
                                    }}
                                />
                            </Button>
                            <Typography variant="p" fontWeight="bold" fontSize={25} textAlign="center" sx={{ color: "var(--secColor)" }}>Open Positions</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);