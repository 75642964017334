import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button, sec_button } from '../app/components/Styles';
import { isDesktop, isIPad13, isMobile, isTablet } from 'react-device-detect';
import { Image, QRCode } from 'antd';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { doc, getDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../app/config";
import { People } from '@mui/icons-material';
import { useState } from 'react';

export const MeetTheTeam = ({ }) => {

    const navigate = useNavigate()
    const [isRedirecting, setIsRedirecting] = useState(false)

    const logView = async (link, search_term) => {
        setIsRedirecting(true);

        const viewRef = await addDoc(collection(db, "views"), {
            search_term,
            scanned_at: serverTimestamp(),
        });

        setTimeout(() => {
            window.location.href = link 
        }, 500);
    }

    return (
        <div>
            {!isRedirecting && <Box>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2, marginBottom: 1 }}>
                    <Typography textAlign="center" fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Recruitment Team</Typography>
                </Box>
                <Grid container spacing={5} sx={{ ...centered_flex_box, marginBottom: 15, padding: 2 }}>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/D4D03AQGhtp6ajvfgVA/profile-displayphoto-shrink_800_800/0/1708711117996?e=1721260800&v=beta&t=bx1rDNuexXAAEfi4kc9XbU7y4i2w2kJyrzYFX2VRuTI"/>
                        {(isTablet || isDesktop) && <Typography>Nancy Ihab</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/nancykamel/", "Nancy Ihab")}>Nancy Ihab</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/nancykamel/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/D4D35AQGuwWfbnGbs2g/profile-framedphoto-shrink_800_800/0/1709112201811?e=1716487200&v=beta&t=MErgmmE1VhkM6Kab6lQmOWCSad1ayZ1iIO6fpCqnqnY"/>
                        {(isTablet || isDesktop) && <Typography>Dareen Tantawy</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/dareen-tantawy-/", "Dareen Tantawy")}>Dareen Tantawy</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/dareen-tantawy-/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/D4D03AQEzGIsLuH-k7Q/profile-displayphoto-shrink_800_800/0/1709989935652?e=1721260800&v=beta&t=wEWZDDtXYMkFJ10zAFLP1xukOwZHeKYZ1mwgKGUSUmo"/>
                        {(isTablet || isDesktop) && <Typography>Yasmine Elemary</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/yasmine-elemary-15014980/", "Yasmine Elemary")}>Yasmine Elemary</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/yasmine-elemary-15014980/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src=""/>
                        {(isTablet || isDesktop) && <Typography>Aynour Deghidy</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/aynourdeghidy/", "Aynour Deghidy")}>Aynour Deghidy</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/aynourdeghidy/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/D4D03AQHTi0tMwEMDdg/profile-displayphoto-shrink_800_800/0/1715081374071?e=1721260800&v=beta&t=8JNVN_mrrmv151qaacqsxCwnUxrEphS29EgFrhb4qxc"/>
                        {(isTablet || isDesktop) && <Typography>Melody Magdy</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/melody-magdy-6b0496228/", "Melody Magdy")}>Melody Magdy</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/nancykamel/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/D4D03AQHLqFcEHQRcjQ/profile-displayphoto-shrink_800_800/0/1708596981758?e=1721260800&v=beta&t=msyi-IK4egCRAcFpObCvZGcXcu7Ksz2_kZeliFhU5Rs"/>
                        {(isTablet || isDesktop) && <Typography>Michael Youssef</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/michael-youssef-abaskhairon-73335522a/", "Michael Youssef")}>Michael Youssef</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/michael-youssef-abaskhairon-73335522a/"/>}
                    </Grid>
                </Grid>
            </Box>}
            {isRedirecting && <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", height: "80vh", padding: 2 }}>
                <Image height={100} width={178.65} preview={false} src={"https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/Henkel_Logo.png"} />
                <Typography fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Redirecting...</Typography>
                <Box sx={centered_flex_box}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Box>}
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MeetTheTeam);