import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { All,  Home, InternRoles, Internal, Redirect, Role, Scan } from "./Pages";
import ProtectedRoute from "./ProtectedRoute";
import { Box, IconButton } from "@mui/material";
import { centered_flex_box } from "./app/components/Styles";
import { ArrowBack } from "@mui/icons-material";
import { MajorRoles } from "./Pages/Majors";
import { MeetTheTeam } from "./Pages/Team";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/HenkelCareers" exact element={<Redirect />} />
      <Route path="/Internship" exact element={<InternRoles />} />
      <Route path="/Team" exact element={<MeetTheTeam />} />
      <Route path="/Scan/:id" exact element={<Scan />} />
      <Route path="/Scan/:id/:major" exact element={<Scan />} />
      <Route path="/AllMajors" exact element={<All />} />
      <Route path="/Role/:major/:id" exact element={<Role />} />
      <Route path="/Major/:id" exact element={<Internal />} />
      <Route path="/:graduate_status/:id" exact element={<MajorRoles />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
